<script>
	import Base from '@backend/Base.vue';
	export default {
		name: "BoUserLevel",
		extends: Base,
		data() {
			return {
				Name: "BoUserLevel",
				mrMenuRole: {},
				mrModuleRole: {},
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		watch: {
			'$route.query'(v) {
				this.refreshData()
			},
			'filter.search'(v) {
				this.search()
			}
		}
	}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="card" v-if="!$route.params.id">
			<div class="card-body">
				<VForm @resp="search">
					<div class="row gutter-10">
						<div class="col-sm-3">
							<h5 class="card-title">{{ObjectName}} List</h5>
						</div>
						<div class="col-9 col-lg-3 col-sm-4 ml-auto">
							<div class="form-group mb-0">
								<div class="input-group">
									<input type="text" v-model="filter.search" class="form-control" placeholder="Search...">
									<div class="input-group-append">
										<button class="btn btn-info" type="button" @click="search()"><i class="fas fa-search"></i></button>
									</div>
								</div>
							</div>
						</div>
						<div class="col-3 col-sm-auto">
							<router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
						</div>
					</div>
				</VForm>
			</div>
			<div class="table-responsive ajax-table">
				<table class="table table-striped table-bordered">
					<thead>
						<tr>
							<th>#</th>
							<th>
								<SortField name="bul_name"></SortField>
							</th>
							<th>{{fields.bul_is_active}}</th>
							<th width="120px">Action</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(v,k) in data.data" :key="k">
							<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
							<td>{{v.bul_name}}</td>
							<td>
								<StatusLabel :status="v.status"></StatusLabel>
							</td>
							<td class="btn-action">
								<router-link v-if="page.moduleRole('Edit')" class="icon_action" :to="{name:Name,params:{id:v.id}}"
									v-tooltip="'Edit'"><i class="ti-marker-alt"></i></router-link>
								<a v-if="page.moduleRole('ChangeStatus')" href="javascript:;" class="icon_action"
									@click="changeStatus(k)" v-tooltip="'Change Status'"><i class="icon-settings"></i></a>
								<a v-if="page.moduleRole('Delete')" href="javascript:;" class="icon_action"
									@click="deleteItem($event,k)" v-tooltip="'Remove'"><i class="ti-trash"></i></a>
							</td>
						</tr>
						<tr v-if="NotFound">
							<td colspan="99">
								<h3 class="tc">{{NotFound}}</h3>
							</td>
						</tr>
						<tr v-if="data.data===false">
							<td colspan="99">
								<LoadingSpinner light></LoadingSpinner>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<Pagination class="justify-content-end mt-3 mb-0" :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
		</div>

		<!-- ======================================================================== 
			FORM CRUD
		============================================================================= -->
		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
						<div class="col-md-8">
							<h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
						</div>
					</div>
					<div class="info"></div>
					<div class="row">
						<div class="col-sm-6">
							<BoField name="bul_name" v-model="row.bul_name"></BoField>
							<BoField name="bul_is_active">
								<div class="row">
									<div class="col-2">
										<radio name="bul_is_active" v-model="row.bul_is_active" option="Y"
											:attr="validation('bul_is_active')">Active</radio>
									</div>
									<div class="col-2">
										<radio name="bul_is_active" v-model="row.bul_is_active" option="N">Inactive</radio>
									</div>
								</div>
							</BoField>
						</div>

						<div class="col-sm-6">
							<BoField name="bul_menu_role">
								<select2 name="bul_menu_role" v-bind="validation('bul_menu_role')" :options="mrMenuRole"
									v-model="row.bul_menu_role" multiple></select2>
							</BoField>

							<BoField name="bul_module_role">
								<select2 name="bul_module_role" v-bind="validation('bul_module_role')" :options="mrModuleRole"
									v-model="row.bul_module_role" multiple></select2>
							</BoField>
						</div>

						<div class="col-12">
							<div class="text-right">
								<button type="submit" class="fcbtn btn btn-info btn-outline btn-rounded btn-loading">{{($route.params.id=='add'?'Add':'Update')+" User Level"}}</button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>